import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { locus } from "../actions";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap";

const FaqItem = ({ question, answer, image }) => (
  <div className="faq-item">
    <br />
    <h3>{question}</h3>
    <br />
    <div dangerouslySetInnerHTML={{ __html: answer }} />
    <br />
    {image.length > 0 &&
      image.map((img, index) => (
        <img key={index} src={img} alt={`Image ${index}`} />
      ))}
  </div>
);

const FAQSearchPage = ({ keywords, setKeywords }) => {
  console.log("keywords", keywords);
  const [activeTab, setActiveTab] = useState("methods");
  const [searchResults, setSearchResults] = useState(null);
  const [Faqs, setFaq] = useState(null);
  const [showSearch, setShowSearch] = useState(false); // State to control visibility of search results

  useEffect(() => {
    setKeywords();
  }, [setKeywords]);

  const handleTabSelect = (keyword) => {
    setActiveTab(keyword);
    setShowSearch(false); // Hide search results when a keyword is clicked
    let headers = {};
    let token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
      headers["Content-Type"] = "application/json";
    }
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ keywords: [keyword] }),
    };
    fetch(`https://devapi.intensel.net/api/v2/keyword_help_v2/`, requestOptions)
      .then((response) => response.json())
      .then((data) => setFaq(data))
      .catch((error) => console.error("Error fetching search results:", error));
  };

  const handleSearchSubmit = (param) => {
    setShowSearch(true); // Show search results when a new search is submitted
    let headers = {};
    let token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const requestOptions = {
      method: "GET",
      headers,
    };
    fetch(
      `https://devapi.intensel.net/api/v2/faq_search/?search=${param}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setSearchResults(data.results))
      .catch((error) => console.error("Error fetching search results:", error));
  };

  return (
    <Container fluid style={{ padding: "0px" }}>
      {/* Header Row */}
      <Row style={{ backgroundColor: "#18202F" }}>
        <Col md={12}>
          <br />
          <br />
          <p
            style={{
              textAlign: "center",
              fontSize: "56px",
              color: "white",
              marginTop: "1%",
            }}
          >
            FAQs
          </p>
        </Col>
      </Row>

      {/* Search Row */}
      <Row style={{ height: "200px", backgroundColor: "#18202F" }}>
        <Col md={2}></Col>
        <Col md={8} className="search-col">
          <Form
            style={{ width: "100%", display: "flex" }}
            onSubmit={(e) => {
              e.preventDefault();
              const searchInput = e.target.elements.searchInput.value;
              handleSearchSubmit(searchInput);
            }}
          >
            <FormControl
              name="searchInput"
              type="text"
              placeholder="Search for answers"
            />
            <Button type="submit" style={{ marginLeft: "2%" }}>
              Search
            </Button>
          </Form>
        </Col>
        <Col md={2}></Col>
      </Row>

      {/* Main Content */}
      <Row>
        <Col md={2}></Col>
        <Col md={3}>
          <br />
          {/* Sidebar */}
          <div className="sidebar">
            <Navbar
              bg="light"
              variant="light"
              style={{ backgroundColor: "white" }}
            >
              <Nav className="flex-column" style={{ width: "100%" }}>
                {keywords.map((keyword, index) => (
                  <div key={index}>
                    <Nav.Link
                      href={`#${keyword.name}`}
                      onClick={() => {
                        handleTabSelect(keyword.name);
                        setSearchResults(null); // Reset search results when a keyword is clicked
                      }}
                      style={{ color: "black" }}
                    >
                      {keyword.name}
                    </Nav.Link>
                    {keyword.children && keyword.children.length > 0 && (
                      <Nav
                        className="flex-column"
                        style={{ marginLeft: "20px" }}
                      >
                        {keyword.children.map((child, childIndex) => (
                          <Nav.Link
                            key={childIndex}
                            href={`#${child}`}
                            onClick={() => {
                              handleTabSelect(child);
                              setSearchResults(null); // Reset search results when a sub keyword is clicked
                            }}
                            style={{ color: "black" }}
                          >
                            {child}
                          </Nav.Link>
                        ))}
                      </Nav>
                    )}
                  </div>
                ))}
              </Nav>
            </Navbar>
          </div>
        </Col>
        <Col md={5}>
          {/* FAQ Content */}
          <div className="main-content">
            {!showSearch ? (
              <div className="faq-content">
                {/* Display keyword content */}
                {Faqs &&
                  Faqs.map((faq, index) => (
                    <FaqItem
                      key={index}
                      question={faq.title}
                      answer={faq.content}
                      image={faq.attachments}
                    />
                  ))}
              </div>
            ) : (
              <div className="search-results">
                {searchResults && searchResults.length > 0 ? (
                  searchResults.map((result, index) => (
                    <FaqItem
                      key={index}
                      question={result.title}
                      answer={result.content}
                      image={result.attachments || []}
                    />
                  ))
                ) : (
                  <p></p>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  keywords: state.locus.keyword || [],
});

const mapDispatchToProps = (dispatch) => ({
  setKeywords: () => dispatch(locus.fetchKeywords()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQSearchPage);
